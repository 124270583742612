import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { jwtDecode } from "jwt-decode";

const REACT_APP_PROXY = process.env.REACT_APP_PROXY

export function useRequest(initial = null) {
  const [data, setData] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * To determine the success of a request, use the res.successful
   * property that is set in a response interceptor in utils/axios.js
   *
   * @param {*} reqConfig See axios.request docs
   * @returns Promise
   */

  const navi = useNavigate();

  axios.defaults.headers.common['x-access-token'] = localStorage.getItem('token');
  axios.defaults.headers.common['Cache-Control'] = 'no-cache';

  function makeRequest(reqConfig) {
    const config = {
      ...reqConfig,
      url: `${REACT_APP_PROXY}${reqConfig.url}`
    };

    // reset our state before dispatching request
    setLoading(true);
    setData(null);
    setError(null);

    return axios.request(config)
      .then((response) => {

        setData(response.data);
        setError(null);

        return response.data;
      })
      .catch((error) => {

        if (error?.response?.status === 401) {
          localStorage.removeItem('token');

          navi('/login');
        }

        setError(error.response.data);

        return error.response.data;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return [makeRequest, data, loading, error];
}

export const getRole = () => {

  try {
    const token = localStorage.getItem('token');

    if (!token) {
      return null;
    }
    const decodedToken = jwtDecode(localStorage.getItem('token'));
    
    if(decodedToken.isSuperAdmin){
      return 'superAdmin';
    }
    if(decodedToken.isAdmin){
      return 'admin';
    }

    return false;
  } catch (error) {
    console.error('Error decoding token.', error);
    return null;
  }
}

export const getUserName = () => {

  return localStorage.getItem('username');
}