import MainCard from '../../ui-component/cards/MainCard';
import React, { useState } from 'react'
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { authHeader } from '../../providers/auth';

import {
    InputLabel,
    OutlinedInput,
  } from '@mui/material';
  

const UploadResume = () => {

    const [files, setFiles] = useState([]);
    const [values, setValues] = useState({
        candidateName: '',
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    return (
        <MainCard title="Upload a Resume" boxShadow shadow={'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'}>
            <InputLabel htmlFor="outlined-adornment-organization-id-register">Candidate Name</InputLabel>
              <OutlinedInput
                id="outlined-adornment-organization-id-register"
                value={values.candidateName}
                name="candidateName"
                fullWidth
                onChange={handleChange}
                required
              />
              <br/>
              <br/>
            <FilePond
                acceptedFileTypes={['application/pdf']}
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={false}
                instantUpload={false}
                credits={false}
                maxFiles={1}
                server={{
                    url: `${process.env.REACT_APP_PROXY}/api/resumes/v1/uploadResumePDF?candidateName=${encodeURIComponent(values.candidateName)}`,
                    process: {
                        headers: authHeader(),
                    },
                    revert: null,
                    fetch: null,
                    load: null,
                    restore: null,
                }}
                name="files" /* sets the file input name, it's filepond by default */
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
        </MainCard>
    )
}

export default UploadResume;