import MainCard from '../../ui-component/cards/MainCard';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react'
import { useRequest } from '../../providers';

import {
    Button,
    Grid,
    InputLabel,
    OutlinedInput,
    Select,
    MenuItem,
    Typography,
    TableRow,
    TableCell
} from '@mui/material';
import MUIDataTable from 'mui-datatables';

const columns = [
    {
        name: "candidateName",
        label: "Candidate Name",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "jobName",
        label: "Job Name",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "rating",
        label: "Rating (Out of 5)",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                return (
                   <>
                   {/* <Chip variant='outlined' label={<strong>{value}</strong>} style={{ borderColor: `${value >= 4 && '#23D228' || value >= 3 && '#FB8500' || 'red'}`, width: '40%', backgroundColor: '#E5E5E6'}} /> */}
                    </>
                 )
              }
        }
    },
    {
        name: "createdAt",
        label: "Analysis Date",
        options: {
            filter: true,
            sort: true,
            sortCompare: (order) => {
                return (obj1, obj2) => {
                    console.log(order, obj1, obj2);
                    return new Date(obj1.data) - new Date(obj2.data) * (order === 'asc' ? 1 : -1);
                };
              }
        }
    }
];

const CandidateFinder = () => {

    const [getAllBusinesses, businessData, loadingBusiness, error] = useRequest();
    const [submitAnalysis, analysisData, loadingAnalysis, analysisError] = useRequest();

    // useEffect(() => {

    //     getAllBusinesses({
    //         method: 'GET',
    //         url: `/api/analysis/v1/getJobsAndCandidates`,
    //     });
    // }, []);

    const [values, setValues] = useState({
        candidateName: '',
        jobSpec: '',
        additionalContext: '',
        candidateId: '',
        jobId: '',
    });

    const handleCandidateChange = (event, key) => {
        const itemKey = key.key.slice(2);
        setValues({
            ...values,
            [event.target.name]: event.target.value,
            candidateId: itemKey
        });
    };

    const handleJobChange = (event, key) => {
        const itemKey = key.key.slice(2);
        setValues({
            ...values,
            [event.target.name]: event.target.value,
            jobId: itemKey
        });
    };

    const handleAdditionalContextChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const createAnalysis = async () => {

        if (!(values.candidateId || values.jobId)) {
            alert('Please populate the candidate and the job you would like to analyse.');
            return;
        }

        const response = await submitAnalysis({
            method: 'POST',
            url: `/api/analysis/v1/createAnalysis`,
            data: {
                candidateId: values.candidateId,
                jobId: values.jobId,
                additionalContext: values.additionalContext
            }
        });

        if (response.success) {
            alert(response.data);
            return;
        }

        alert(response.messsage);

    }

    console.log(businessData);

    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'standard',
        expandableRows: true,
        expandableRowsHeader: false,
        selectableRows: false,
        expandableRowsOnClick: true,
        sortOrder: {
            direction: 'desc',
            name: 'createdAt'
        },
        rowsPerPage: 100,
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const data = parseData();
            console.log(data[rowMeta.dataIndex]);
            console.log(data[rowMeta.dataIndex].summary);

            return (
                <>
                    <TableRow>
                        <TableCell colSpan={colSpan}> <h3>AI Feedback:</h3> {data[rowMeta.dataIndex].summary}</TableCell>
                    </TableRow>

                    {data[rowMeta.dataIndex].additionalContext &&
                        <TableRow>
                            <TableCell colSpan={colSpan}> <h3>Additional Context (User Provided):</h3> {data[rowMeta.dataIndex].additionalContext}</TableCell>
                        </TableRow>
                    }
                </>
            );
        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
            console.log(curExpanded, allExpanded, rowsExpanded),
    };

    return (
        <MainCard title="Keyword Finder" boxShadow shadow={'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'}>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingAnalysis}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
            <Grid container direction="row" justifyContent="space-between" flexGrow={1}>
                <Grid item xs={12} lg={5}>
                    <InputLabel htmlFor="outlined-adornment-organization-id-register">Candidate Name</InputLabel>
                    {/* <Select
                        id="outlined-adornment-organization-id-register"
                        value={values.candidateName}
                        name="candidateName"
                        fullWidth
                        onChange={handleCandidateChange}
                    >
                        {!Array.isArray(businessData?.data) && businessData?.data?.candidates.map(x => {
                            return (
                                <MenuItem key={x.uuid} value={x.candidateName}>{x.candidateName}</MenuItem>
                            );
                        })}
                    </Select> */}

                </Grid>
                <Grid item xs={12} lg={5}>
                    <InputLabel htmlFor="outlined-adornment-organization-id-register">Job Spec</InputLabel>
                    {/* <Select
                        id="outlined-adornment-organization-id-register"
                        value={values.jobSpec}
                        name="jobSpec"
                        fullWidth
                        onChange={handleJobChange}
                    >
                        {!Array.isArray(businessData?.data) && businessData?.data?.jobs.map(x => {
                            return (
                                <MenuItem key={x.uuid} value={x.name}>{x.name}</MenuItem>
                            );
                        })}
                    </Select> */}

                </Grid>
                <Grid item xs={12} lg={12} marginTop={'25px'}>
                    <InputLabel htmlFor="outlined-adornment-organization-id-register">Is there something the AI should take into consideration?</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-organization-id-register"
                        value={values.additionalContext}
                        name="additionalContext"
                        fullWidth
                        onChange={handleAdditionalContextChange}
                        multiline
                    />

                </Grid>

                <Grid item xs={12} lg={12} marginTop={'25px'}>
                    <Button
                        disableElevation
                        disabled={loadingAnalysis}
                        onClick={createAnalysis}
                        fullWidth size="large"
                        type="submit"
                        variant="contained"
                        color="primary">
                        Analyse
                    </Button>

                </Grid>

                <Grid item xs={12} lg={12} marginTop={'25px'}>
                    <MUIDataTable
                        title={<Typography variant="h3">Analysis List</Typography>}
                        data={businessData?.data ? parseData() : []}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
        </MainCard>
    );
}

export default CandidateFinder;