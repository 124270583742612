import * as React from 'react';
import MUIDataTable from "mui-datatables";
import { getRole, useRequest } from '../../providers';
import { useEffect } from 'react';
import { Typography, Chip } from '@mui/material';

import { ThreeDotMenu } from 'src/ui-component/ThreeDotMenu';
import { DeleteOutline, SupervisorAccount, SupervisorAccountOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';

export const ManageBusiness = () => {

    let [getAllBusinesses, businessData] = useRequest();
    const [removeResume] = useRequest();

    useEffect(() => {

        getAllBusinesses({
            method: 'GET',
            url: `/api/organization/v1/users`,
        });
    }, []);

    const dotMenuActions = (role, id) => {

        const list = [];
        if (!role) {
            list.push({
                label: 'Make Admin',
                onClick: () => promoteAction(id),
                icon: <SupervisorAccountOutlined />,
                textColor: 'green'
            },
                {
                    label: 'Deactivate',
                    onClick: () => removeResumeAction(id),
                    icon: <DeleteOutline />,
                    textColor: 'red'
                });
        }

        return list;
    }

    const removeResumeAction = async (resumeId) => {

        if (!resumeId) {
            toast.error('No valid user selected.');
            return;
        }

        const response = await removeResume({
            method: 'POST',
            url: '/api/user/v1/updateProfile',
            data: {
                uuid: resumeId
            }
        });

        if (!response.success) {
            toast.error('Something went wrong. Please try again later.');
            return;
        }
    };

    const promoteAction = async (resumeId) => {

        if (!resumeId) {
            toast.error('No valid user selected.');
            return;
        }

        const response = await removeResume({
            method: 'POST',
            url: '/api/user/v1/promote',
            data: {
                uuid: resumeId
            }
        });

        if (!response.success) {
            toast.error('Something went wrong. Please try again later.');
            return;
        }
    };

    const columns = [
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "status",
            label: "Account Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <>
                            <Chip variant='outlined' label={value ? value : 'activated'} style={{ borderColor: `${value && '#FB8500' || '#23D228'}`, width: '40%', backgroundColor: '#E5E5E6' }} />
                        </>
                    )
                }
            }
        },
        {
            name: "hasRegistered",
            label: "Invitation Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <>
                            <Chip variant='outlined' label={value ? 'accepted' : 'email invite sent'} style={{ borderColor: `${value && '#23D228' || '#FB8500'}`, width: '40%', backgroundColor: '#E5E5E6' }} />
                        </>
                    )
                }
            }
        },
        {
            name: "isAdmin",
            label: "Admin",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <>
                            {value &&
                                <Chip variant='outlined' label={'admin'} style={{ borderColor: `${value && '#23D228'}`, width: '40%', backgroundColor: '#E5E5E6' }} />
                            }
                        </>
                    )
                }
            }
        },
        {
            name: "",
            label: "",
            options: {
                customBodyRender: (data, dataIndex, rowIndex) => {
                    const isAdmin = businessData.data[dataIndex.rowIndex].isAdmin;
                    const id = businessData.data[dataIndex.rowIndex].uuid

                    return (
                        <>
                            {!isAdmin &&
                                <ThreeDotMenu options={dotMenuActions(isAdmin, id)} />
                            }
                        </>
                    );
                }
            }
        },
    ];

    const options = {
        filter: true,
        pagination: false,
        filterType: 'dropdown',
        responsive: 'standard',
        selectableRows: 'none',
    };

    return (
        <div style={{ flexGrow: 1 }}>
            <MUIDataTable
                title={<Typography variant="h3">Manage your business</Typography>}
                data={businessData?.data ? businessData.data : []}
                columns={columns}
                options={options}
            />
        </div>
    );
}
