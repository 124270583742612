import { useRoutes } from 'react-router-dom';

// routes
import { CombinedRoutes } from './CombinedRoutes';
import { authHeader } from '../providers/auth';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes(CombinedRoutes(authHeader()));
}
