import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'src/providers';
import { Typography, Container, TextField, CssBaseline, Button} from '@mui/material'

const ForgotPassword = (props) => {

  const history = useNavigate();
  const [email, setEmail] = useState('');  
  const [errorText, setErrorText] = useState('');

  const [registerUserReq, data, loading, error] = useRequest();

  const registerUser = () => {
    if (!email || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setErrorText('Please provide a valid email address');
      return;
    }

    registerUserReq({
      method: 'POST',
      url: '/api/auth/v1/customer-forgot-password',
      data: {
        username: email
      }
    }).then((response) => {
      setErrorText('');

      if (response?.success === true) {
        alert(response.message);
        history('/login')
      }
    });
  };

  return (
    <Container maxWidth={'xs'} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%'}}>
      <CssBaseline />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(data) => setEmail(data.target.value)}
            autoFocus
          />
          {(errorText || error)
            && (
              <Typography variant="body2" color="error" align="center">
                {errorText || error?.data?.message || 'An Unknown Error Occurred'}
              </Typography>
            )}
          <Button
            fullWidth
            variant="contained"
            style={{ color: "primary", borderRadius: 50, paddingTop: 12 }}
            onClick={() => registerUser()}
          >
            Reset Password
          </Button>
      </Container>
  );
};

export default ForgotPassword;