import * as React from 'react';
import MUIDataTable from "mui-datatables";
import { useRequest } from '../../providers';
import { useEffect, useState } from 'react';
import { Typography, TableRow, TableCell, Button } from '@mui/material';

import { Buffer } from "buffer";

const BusinessStats = () => {

    const [getAllBusinesses, businessData, loadingBusiness, error] = useRequest();

    const [downloadFileFromBlob, blobData, loadingBlob, errorBlob] = useRequest();
    const [page, setPage] = useState(0);

    useEffect(() => {

        getAllBusinesses({
            method: 'GET',
            url: `/api/organization/v1/data`,
        });
    }, []);

    const downloadFile = async (jobId) => {

        if (!jobId) {
            alert('Something went wrong. E22');
            return;
        }

        const response = await downloadFileFromBlob({
            method: 'POST',
            url: `/api/jobs/v1/downloadSpec`,
            data: {
                jobId: jobId
            }
        });

        if (!response.success) {
            alert('Something went wrong. Please try again later.');
            return;
        }

        const buffer = Buffer.from(response.data);
        const blob = new Blob([buffer]);

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.target = '_self'
        a.style = "display: none";
        a.href = url;
        a.download = `${jobId}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const columns = [
        {
            name: "businessName",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            }
        },
    ];

    const subTableColumns = [{
        name: "rating",
        label: "Rating",
        options: {
            filter: true,
            sort: true,
            options: {
                setCellProps: () => ({ style: { minWidth: "800px", maxWidth: "800px" } }),
                customBodyRender: (data, type, row) => { return <pre>{data}</pre> }
            },
        }
    },
    {
        name: "job.name",
        label: "Job Name",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "resume.candidateName",
        label: "Candidate Name",
        options: {
            filter: true,
            sort: true,
        }
    }]

    const options = {
        filter: true,
        pagination: false,
        filterType: 'dropdown',
        responsive: 'standard',
        expandableRows: true,
        selectableRows: 'none',
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;

            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        <div style={{
                            maxHeight: '400px',
                            overflow: 'scroll'
                        }}>
                        <MUIDataTable
                            data={businessData.data[rowMeta.dataIndex].analyses}
                            columns={subTableColumns}
                            options={{
                                filter: false,
                                search: false,
                                print: false,
                                download: false,
                                viewColumns: false,
                                customToolbar: null,
                                responsive: 'vertical',
                                selectableRows: 'none',
                                enableNestedDataAccess: '.',
                                pagination: false,
                                selectToolbarPlacement: 'none',

                            }}
                        />
                        </div>
                    </TableCell>
                </TableRow>
            );
        },
    };

    return (
        <div style={{ flexGrow: 1 }}>
            <MUIDataTable
                title={<Typography variant="h3">Business List</Typography>}
                data={businessData?.data ? businessData.data : []}
                columns={columns}
                options={options}
            />
        </div>
    );
}

export default BusinessStats;