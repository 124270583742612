// assets
import { IconPlus, IconEye, IconGraph } from '@tabler/icons-react';

// ==============================|| JOBS MENU ITEMS ||============================== //

export const admin = {
    id: 'admin',
    title: 'Admin',
    type: 'group',
    children: [
        {
            id: 'create-business',
            title: 'Add a Business',
            type: 'item',
            url: '/business/create-business',
            icon: IconPlus,
            breadcrumbs: false
        },
        // {
        //     id: 'manage-business',
        //     title: 'Manage Businesses',
        //     type: 'item',
        //     url: '/app/admin/manage-businesses',
        //     icon: IconPlus,
        //     breadcrumbs: false
        // },
        {
            id: 'list-business',
            title: 'View Businesses',
            type: 'item',
            url: '/business/list-business',
            icon: IconEye,
            breadcrumbs: false
        },
        {
            id: 'view-business',
            title: 'Business Stats',
            type: 'item',
            url: '/business/data-business',
            icon: IconGraph,
            breadcrumbs: false
        }
    ]
};
