import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const API_URL = `${process.env.REACT_APP_PROXY}/api/auth/`;

export const login = (username, password) => axios
  .post(`${API_URL}signin`, {
    username,
    password
  })
  .then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem('token', JSON.stringify(response.data.accessToken));
    }

    return response.data;
  });

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('isAdmin');
};

export const register = (username, email, password) => axios.post(`${API_URL}signup`, {
  username,
  email,
  password
});

export const getCurrentUser = () => JSON.parse(localStorage.getItem('token'));

export const authHeader = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentDate = new Date();

    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      return false;
    }

    return { 'x-access-token': token };
  } catch (error) {
    console.error(error);
    return false;
  }
};
