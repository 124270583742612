import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router";
import { useRequest } from 'src/providers';
import { Typography, Container, TextField, CssBaseline, Button} from '@mui/material'

const PasswordReset = (props) => {

  const history = useNavigate();

  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');

  const [registerUserReq, data, loading, error] = useRequest();

  const { id } = useParams();

  const registerUser = () => {

    if (password !== confirmedPassword) {
      setErrorText('Your passwords need to match.');
      return;
    }

    registerUserReq({
      method: 'POST',
      url: `/api/auth/v1/customer-reset-password/${id}`,
      data: {
        password
      }
    }).then((response) => {
      setErrorText('');

      if (response?.success) {
        alert(response.message);
        history('/login')
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%'}}>
      <CssBaseline />
        <form>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={(data) => setPassword(data.target.value)}
            autoComplete="current-password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirm-password"
            label="Confirm Password"
            type="password"
            id="confirm-password"
            onChange={(data) => setConfirmedPassword(data.target.value)}
          />
          {(errorText || error)
            && (
              <Typography variant="body2" color="error" align="center">
                {errorText || error?.data?.message || 'An Unknown Error Occurred'}
              </Typography>
            )}
          <Button
            fullWidth
            variant="contained"
            style={{ color: "primary", borderRadius: 50, paddingTop: 12 }}
            onClick={() => registerUser()}
          >
            Reset Password
          </Button>
        </form>
    </Container>
  );
};

export default PasswordReset;