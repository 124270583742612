import * as React from 'react';
import MUIDataTable from "mui-datatables";
import { useRequest } from '../../providers';
import { useEffect, useState } from 'react';
import { Typography, TableRow, TableCell, Button } from '@mui/material';
import { ThreeDotMenu } from 'src/ui-component/ThreeDotMenu';
import { DeleteOutline } from '@mui/icons-material';

import { Buffer } from "buffer";

const JobList = () => {

    const [getAllBusinesses, businessData, loadingBusiness, error] = useRequest();
    const [deleteSpecs, specDeleteData, loadingSpecDelete, errorSpecDelete] = useRequest();
    const [downloadFileFromBlob, blobData, loadingBlob, errorBlob] = useRequest();
    const [removeResume, resumeRemovalData, loadingResumeRemoval, errorResumeRemoval] = useRequest();
    const [page, setPage] = useState(0);
    const [stateData, setStateData] = useState([]);

    useEffect(() => {

        getAllBusinesses({
            method: 'GET',
            url: `/api/jobs/v1/getJobList`,
        }).then((x) => {
            setStateData(x.data)
        });
    }, []);

    const downloadFile = async (jobId) => {

        if (!jobId) {
            alert('Something went wrong. E22');
            return;
        }

        const response = await downloadFileFromBlob({
            method: 'POST',
            url: `/api/jobs/v1/downloadSpec`,
            data: {
                jobId: jobId
            }
        });

        if (!response.success) {
            alert('Something went wrong. Please try again later.');
            return;
        }

        const buffer = Buffer.from(response.data);
        const blob = new Blob([buffer]);

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.target = '_self'
        a.style = "display: none";
        a.href = url;
        a.download = `${jobId}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const removeResumeAction = async (resumeId) => {

        if (!resumeId) {
            alert('Something went wrong. No valid resume selected.');
            return;
        }

        const response = await removeResume({
            method: 'POST',
            url: '/api/jobs/v1/deleteSpec',
            data: {
                jobs: [resumeId]
            }
        });

        if (!response.success) {
            alert('Something went wrong. Please try again later.');
            return;
        }
    };

    const columns = [
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "uuid",
            label: "Download Spec",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (data) => {
                    return (
                        <Button download onClick={() => downloadFile(data)}>Download</Button>
                    );
                }
            }
        },
        {
            name: "",
            label: "",
            options: {
                customBodyRender: (data, dataIndex, rowIndex) => {
                    return (
                        <ThreeDotMenu options={[{
                            label: 'Delete',
                            onClick: () => {

                                const newData = stateData;
                                removeResumeAction(stateData[dataIndex.rowIndex].uuid);
                                newData.splice(dataIndex.rowIndex, 1);
                                setStateData([...newData]);

                            },
                            icon: <DeleteOutline />,
                            textColor: 'red'
                        }]} />
                    );
                }
            }
        },
    ];

    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'standard',
        selectableRows: false,
        expandableRows: true,
        expandableRowsHeader: false,
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            console.log(rowData, rowMeta)
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}> <h3>Job Text:</h3> {stateData[rowMeta.dataIndex].rawText}</TableCell>
                </TableRow>
            );
        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
            console.log(curExpanded, allExpanded, rowsExpanded),
    };

    return (
        // <MainCard title="All Job Listings">
        <div style={{ flexGrow: 1 }}>
            <MUIDataTable
                title={<Typography variant="h3">Job List</Typography>}
                data={stateData ? stateData : []}
                columns={columns}
                options={options}
            />
        </div>
        // </MainCard>
    );
}

export default JobList;