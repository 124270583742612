// assets
import { PeopleAltOutlined } from '@mui/icons-material';
import { IconPlus, IconEye, IconGraph } from '@tabler/icons-react';

// ==============================|| JOBS MENU ITEMS ||============================== //

export const business = {
    id: 'business',
    title: 'Business',
    type: 'group',
    children: [
        {
            id: 'manage-people',
            title: 'Manage Business',
            type: 'item',
            url: '/business/manage-business',
            icon: PeopleAltOutlined,
            breadcrumbs: false
        },
        {
            id: 'add-people',
            title: 'Add People',
            type: 'item',
            url: '/business/add-people',
            icon: IconPlus,
            breadcrumbs: false
        }
    ]
};
