import * as React from 'react';
import MUIDataTable from "mui-datatables";

import { useEffect } from 'react';
import { Typography, Chip, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { useRequest } from '../../providers';
import { ThreeDotMenu } from 'src/ui-component/ThreeDotMenu';
import { Check, PhoneCallback, Star } from '@mui/icons-material';
import { IconSparkles } from '@tabler/icons-react';
import { useState } from 'react';
import { GeneratedQuestionModal } from 'src/ui-component/GeneratedQuestionModal';
import { styled } from '@mui/system';
import NestedAnalysisTable from './NestedTable';

const AnalysisList = () => {

    const [getAllBusinesses, businessData, loadingBusiness, error] = useRequest();
    const [removeResume, resumeRemovalData, loadingResumeRemoval, errorResumeRemoval] = useRequest();
    const [stateData, setStateData] = useState([]);
    const [showGeneratedQuestions, setShowGeneratedQuestions] = useState(false);
    const [generatedQuestions, setGeneratedQuestions] = useState('');

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#eef2f6',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    useEffect(() => {
        getAllBusinesses({
            method: 'GET',
            url: `/api/resumes/v2/getAnalysisList`,
        }).then((x) => {
            // const parsedData = parseData(x);
            setStateData(x.data);
        });
    }, []);

    const updateResumeAction = async (analysisId) => {

        setGeneratedQuestions('');

        if (!analysisId) {
            alert('Something went wrong. No valid analysis selected.');
            return;
        }

        const response = await removeResume({
            method: 'POST',
            url: '/api/analysis/v1/createScreeningQuestions',
            data: {
                analysisId
            }
        });

        if (!response.success) {
            alert('Something went wrong. Please try again later.');
            return;
        }

        setGeneratedQuestions(response.data)
    };

    const columns = [
        {
            name: "jobName",
            label: "Job",
            options: {
                filter: true,
                sort: true,
                // customBodyRender: (value) => {
                //     return (
                //         <>
                //             <Chip variant='outlined' label={<strong>{value}</strong>} style={{ borderColor: `${value >= 4 && '#23D228' || value >= 3 && '#FB8500' || 'red'}`, width: '40%', backgroundColor: '#E5E5E6' }} />
                //         </>
                //     )
                // }
            }
        },
        {
            name: "candidates",
            label: "Average Rating",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (list) => {
                    const ratings = list.map(candidate => parseFloat(candidate.rating));
                    const totalRatings = ratings.reduce((sum, rating) => sum + rating, 0);
                    const averageRating = ratings.length > 0 ? totalRatings / ratings.length : 0;

                    const value = averageRating.toFixed(2);

                    const valueClass = value >= 4 ? 'high' : (value < 4 && value >= 2) && 'average' || 'very low';
                    return (
                        <>
                            <HtmlTooltip
                                title={
                                    <>
                                        <Typography color="inherit">The overall score is <b>{valueClass}</b></Typography>
                                        {`Consider candidates over or close to this score would be preffered.`}
                                    </>
                                }
                            >
                                <Chip variant='outlined' label={<strong>{value}</strong>} style={{ borderColor: `${value >= 4 && '#23D228' || value >= 3 && '#FB8500' || 'red'}`, width: '40%', backgroundColor: '#E5E5E6' }} />

                            </HtmlTooltip>
                        </>
                    )

                }
            }
        },
        {
            name: "status",
            label: "Job Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <>
                            <Chip variant='outlined' label={<strong>{value}</strong>} style={{ borderColor: `${value === 'active' && '#23D228' || '#FB8500'}`, width: '70%', backgroundColor: '#E5E5E6' }} />
                        </>
                    )
                }
            }
        },
    ];


    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'standard',
        expandableRows: true,
        expandableRowsHeader: false,
        selectableRows: 'none',
        rowsPerPage: 100,
        renderExpandableRow: (rowData, rowMeta) => {
            console.log(rowMeta)

            return (
                    <TableRow>
                        <TableCell colSpan={rowData.length + 3}>
                            <NestedAnalysisTable data={stateData[rowMeta.rowIndex]} />
                        </TableCell>
                    </TableRow>
            );
        }
    };

    return (
        <div style={{ flexGrow: 1 }}>
            <MUIDataTable
                title={<Typography variant="h3">Analysis List</Typography>}
                data={stateData ? stateData : []}
                columns={columns}
                options={options}
            />

            <GeneratedQuestionModal loading={loadingResumeRemoval} open={showGeneratedQuestions} handleState={setShowGeneratedQuestions} content={generatedQuestions} />
        </div>
    );
}

export default AnalysisList;