
// assets
import { IconEye, IconSparkles, Icon3dCubeSphere } from '@tabler/icons-react';

// ==============================|| JOBS MENU ITEMS ||============================== //

export const analysis = {
    id: 'analysis',
    title: 'Analysis',
    type: 'group',
    icon: IconEye,
    children: [
        {
            id: 'perform-analysis',
            title: 'Add a analysis',
            type: 'item',
            url: '/analysis/perform-analysis',
            icon: IconSparkles,
            breadcrumbs: false
        },
        {
            id: 'bulk-analysis',
            title: 'Bulk analysis',
            type: 'item',
            url: '/analysis/bulk-analysis',
            icon: Icon3dCubeSphere,
            breadcrumbs: false
        },
        {
            id: 'list-analysis',
            title: 'List analysis',
            type: 'item',
            url: '/analysis/list-analysis',
            icon: IconEye,
            breadcrumbs: false
        },
    ]
};
