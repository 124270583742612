// assets
import { IconPlus, IconEye, IconUsersPlus } from '@tabler/icons-react';

// ==============================|| JOBS MENU ITEMS ||============================== //

export const resumes = {
    id: 'resumes',
    title: 'Resumes',
    type: 'group',
    children: [
        {
            id: 'bulk-resumes',
            title: 'Add Resumes',
            type: 'item',
            url: '/resumes/bulk-resumes',
            icon: IconUsersPlus,
            breadcrumbs: false
        },
        {
            id: 'list-resumes',
            title: 'List Resumes',
            type: 'item',
            url: '/resumes/list-resumes',
            icon: IconEye,
            breadcrumbs: false
        }
    ]
};
