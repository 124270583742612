import * as React from 'react';
import MUIDataTable from "mui-datatables";

import { useEffect } from 'react';
import { Typography, Chip, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { useRequest } from '../../providers';
import { ThreeDotMenu } from 'src/ui-component/ThreeDotMenu';
import { Check, PhoneCallback, Star } from '@mui/icons-material';
import { IconSparkles } from '@tabler/icons-react';
import { useState } from 'react';
import { GeneratedQuestionModal } from 'src/ui-component/GeneratedQuestionModal';
import { styled } from '@mui/system';

const NestedAnalysisTable = ({ data }) => {

    const parseData = (x) => {
        let modifiedData = [];
        console.log(x)

        for (const item of x.candidates) {
            console.log(x)
            modifiedData.push({
                rating: item.rating,
                // jobName: item.job.name,
                candidateName: item.candidateName,
                summary: item.summary,
                additionalContext: item.additionalContext,
                createdAt: new Date(item.createdAt).toLocaleString(),
                // jobId: item.job.uuid,
                // candidateId: item.resume.uuid,
                uuid: item.uuid
            });

            modifiedData.sort((obj1, obj2) => {
                return new Date(obj1.createdAt) - new Date(obj2.createdAt) * -1
            })
        }

        return modifiedData;
    }


    const [getAllBusinesses, businessData, loadingBusiness, error] = useRequest();
    const [removeResume, resumeRemovalData, loadingResumeRemoval, errorResumeRemoval] = useRequest();
    const [stateData, setStateData] = useState(parseData(data));
    const [showGeneratedQuestions, setShowGeneratedQuestions] = useState(false);
    const [generatedQuestions, setGeneratedQuestions] = useState('');

    // useEffect(() => {
    //     getAllBusinesses({
    //         method: 'GET',
    //         url: `/api/resumes/v2/getAnalysisList`,
    //     }).then((x) => {
    //         // const parsedData = parseData(x);
    //         setStateData(x.data);
    //     });
    // }, []);

    const updateResumeAction = async (analysisId) => {

        setGeneratedQuestions('');

        if (!analysisId) {
            alert('Something went wrong. No valid analysis selected.');
            return;
        }

        const response = await removeResume({
            method: 'POST',
            url: '/api/analysis/v1/createScreeningQuestions',
            data: {
                analysisId
            }
        });

        if (!response.success) {
            alert('Something went wrong. Please try again later.');
            return;
        }

        setGeneratedQuestions(response.data)
    };

    const columns = [
        {
            name: "candidateName",
            label: "Candidate Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "rating",
            label: "Rating (Out of 5)",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <>
                            <Chip variant='outlined' label={<strong>{value}</strong>} style={{ borderColor: `${value >= 4 && '#23D228' || value >= 3 && '#FB8500' || 'red'}`, width: '40%', backgroundColor: '#E5E5E6' }} />
                        </>
                    )
                }
            }
        },
        {
            name: "createdAt",
            label: "Analysis Date",
            options: {
                filter: true,
                sort: true,
                sortCompare: (order) => {
                    return (obj1, obj2) => {
                        return new Date(obj1.createdAt) - new Date(obj2.createdAt) * (order === 'asc' ? 1 : -1);
                    };
                }
            }
        },
        {
            name: "uuid",
            label: " ",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (data, dataIndex, rowIndex) => {
                    return (
                        <ThreeDotMenu id={data} options={[{
                            label: 'Pre-Screening Questions',
                            onClick: () => {
                                updateResumeAction(data)
                                setShowGeneratedQuestions(true);
                            },
                            icon: <IconSparkles />,
                            // textColor: '#5e35b1'
                        }]} />
                    );
                }
            }
        },
    ];

    const options = {
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        customToolbar: null,
        // filter: true,
        // filterType: 'dropdown',
        responsive: 'vertical',
        expandableRows: true,
        expandableRowsHeader: false,
        selectableRows: 'none',
        // expandableRowsOnClick: true,
        sortOrder: {
            direction: 'desc',
            name: 'rating'
        },
        rowsPerPage: 15,
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            console.log(rowData)

            return (
                <>
                    <TableRow>
                        <TableCell colSpan={colSpan}> <h3>AI Feedback:</h3> {stateData[rowMeta.dataIndex].summary}</TableCell>
                    </TableRow>

                    {stateData[rowMeta.dataIndex].additionalContext &&
                        <TableRow>
                            <TableCell colSpan={colSpan}> <h3>Additional Context (User Provided):</h3> {stateData[rowMeta.dataIndex].additionalContext}</TableCell>
                        </TableRow>
                    }
                </>
            );
        }
    };

    return (
        <div style={{ flexGrow: 1 }}>
            <MUIDataTable
                // title={<Typography variant="h3">Analysis List</Typography>}
                data={data ? parseData(data) : []}
                columns={columns}
                options={options}
            />

            <GeneratedQuestionModal loading={loadingResumeRemoval} open={showGeneratedQuestions} handleState={setShowGeneratedQuestions} content={generatedQuestions} />
        </div>
    );
}

export default NestedAnalysisTable;