// assets
import { IconPlus, IconEye } from '@tabler/icons-react';

// ==============================|| JOBS MENU ITEMS ||============================== //

export const jobs = {
    id: 'jobs',
    title: 'Jobs',
    type: 'group',
    children: [
        {
            id: 'create-job',
            title: 'Add a Job',
            type: 'item',
            url: '/jobs/create-job',
            icon: IconPlus,
            breadcrumbs: false
        },
        {
            id: 'list-jobs',
            title: 'List Jobs',
            type: 'item',
            url: '/jobs/list-jobs',
            icon: IconEye,
            breadcrumbs: false
        }
    ]
};
