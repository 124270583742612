import * as React from 'react';
import { useRequest } from '../../providers';
import { useState } from 'react';
import { Chip, Autocomplete, TextField, Backdrop, Grid, Button, CircularProgress } from '@mui/material';
import MainCard from 'src/ui-component/cards/MainCard';
import { toast } from 'react-toastify';

export const AddPeople = () => {

    const [receivers, setReceivers] = useState([])
    const [errorText, setErrorText] = useState('');
    const [submitAllEmails] = useRequest();

    const submitEmails = async () => {

        for (const email of receivers) {
            if (!email || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                setErrorText(`Please provide a valid email address for ${email}`);
                return;
            }
        }

        const response = await submitAllEmails({
            method: 'POST',
            url: '/api/organization/v1/invite',
            data: {
                emailList: receivers
            }
        });

        if (!response.success) {
            toast.error('Something went wrong. Please try again later.');
            return;
        }

        toast.info('The invites have been sent. You can check the status in the manage tab.');
    }



    return (
        <MainCard title="Add People" boxShadow shadow={'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'}>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={false}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
            <Grid container direction="row" justifyContent="space-between" flexGrow={1}>
                <Grid item xs={12} lg={12}>
                    <Autocomplete
                        multiple
                        onChange={(e, value) => setReceivers((state) => value)}
                        id="tags-filled"
                        options={[]}
                        defaultValue={[]}
                        freeSolo
                        renderTags={(value, getTagProps) => {

                            return value.map((option, index) => (
                                <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Emails"
                                error={!!errorText}
                                helperText={errorText}

                            />
                        )}
                    />

                </Grid>
                <Grid item xs={12} lg={12} marginTop={'25px'}>
                    <Button
                        disableElevation
                        disabled={false}
                        onClick={submitEmails}
                        fullWidth size="large"
                        type="submit"
                        variant="contained"
                        color="primary">
                        Invite
                    </Button>

                </Grid>
            </Grid>
        </MainCard>
    );
}
