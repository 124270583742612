import MainCard from '../../ui-component/cards/MainCard';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react'
import { toast, Bounce } from 'react-toastify';
import { useRequest } from '../../providers';


import {
    Button,
    Grid,
    InputLabel,
    OutlinedInput,
    Select,
    MenuItem,
    TextField,
    Autocomplete
} from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";


const BulkAnalysis = () => {

    const [getAllBusinesses, businessData, loadingBusiness, error] = useRequest();
    const [submitAnalysis, analysisData, loadingAnalysis, analysisError] = useRequest();

    useEffect(() => {

        getAllBusinesses({
            method: 'GET',
            url: `/api/analysis/v1/getJobsAndCandidates`,
        }).then(response => {
            if (response.success && Array.isArray(response.data)) {
                const isJob = response.message.includes('jobs');
                toast.info(<>
                <p>It looks like you haven&#39;t uploaded a {isJob ? 'job spec' : 'resume '} yet.</p>
                <a href={isJob ? '/jobs/create-job': '/resumes/bulk-resumes'}>Add a {isJob ? 'Job Spec' : 'Resume'} here.</a>
                </>, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
            }
        });
    }, []);

    const [values, setValues] = useState({
        candidateList: [],
        jobId: '',
    });

    const handleCandidateChange = (event, value) => {
        setValues({
            ...values,
            candidateList: value.map(x => x.uuid)
        });
    };

    const handleJobChange = (event, key) => {
        const itemKey = key.key.slice(2);
        setValues({
            ...values,
            [event.target.name]: event.target.value,
            jobId: itemKey
        });
    };

    const handleAdditionalContextChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const createAnalysis = async () => {

        if (!(values.candidateList || values.jobId)) {
            toast.error('Please select the candidate and the job you would like to analyse.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
            return;
        }

        const response = await submitAnalysis({
            method: 'POST',
            url: `/api/analysis/v1/bulkAnalysis`,
            data: {
                candidateList: values.candidateList,
                jobId: values.jobId,
            }
        });

        if (response.success) {
            toast.success(response.data,{
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
            return;
        }

        alert(response.messsage);

    }

    console.log(businessData)
    return (
        <MainCard title="Bulk Analysis" boxShadow shadow={'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'}>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingAnalysis}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
            <Grid container direction="row" justifyContent="space-between" flexGrow={1}>
                <Grid item xs={12} lg={12}>
                    <Autocomplete
                        multiple
                        options={businessData?.data?.candidates|| []}
                        getOptionLabel={(option) => option.candidateName}
                        disableCloseOnSelect
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                label="Candidates"
                            />
                        )}
                        onChange={handleCandidateChange}
                        renderOption={(props, option, { selected }) => (
                            <MenuItem
                                {...props}
                                key={option.uuid}
                                value={option.uuid}
                                sx={{ justifyContent: "space-between" }}
                            >
                                {option.candidateName}
                                {selected ? <CheckIcon color="info" /> : null}
                            </MenuItem>
                        )}
                    />

                </Grid>
                <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="outlined-adornment-organization-id-register">Job Spec</InputLabel>
                    <Select
                        id="outlined-adornment-organization-id-register"
                        value={values.jobSpec}
                        name="jobSpec"
                        fullWidth
                        onChange={handleJobChange}
                    >
                        {!Array.isArray(businessData?.data) && businessData?.data?.jobs.map(x => {
                            return (
                                <MenuItem key={x.uuid} value={x.name}>{x.name}</MenuItem>
                            );
                        })}
                    </Select>

                </Grid>
                <Grid item xs={12} lg={12} marginTop={'25px'}>
                    <Button
                        disableElevation
                        disabled={loadingAnalysis}
                        onClick={createAnalysis}
                        fullWidth size="large"
                        type="submit"
                        variant="contained"
                        color="primary">
                        Analyse
                    </Button>

                </Grid>
            </Grid>
        </MainCard>
    );
}

export default BulkAnalysis;